const Medium = (props: any) => {
  return (
    <svg
      width="24"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.76875 0.571777C10.5072 0.571777 13.5375 3.62325 13.5375 7.38704C13.5375 11.1509 10.5069 14.2021 6.76875 14.2021C3.03075 14.2021 0 11.1509 0 7.38704C0 3.62335 3.03047 0.571777 6.76875 0.571777ZM17.5781 0.970966C19.4473 0.970966 20.9626 3.84329 20.9626 7.38704H20.9629C20.9629 10.93 19.4476 13.8031 17.5785 13.8031C15.7094 13.8031 14.194 10.93 14.194 7.38704C14.194 3.84422 15.709 0.970966 17.5782 0.970966H17.5781ZM22.8097 1.63931C23.4669 1.63931 24 4.21266 24 7.38714C24 10.5606 23.4671 13.135 22.8097 13.135C22.1522 13.135 21.6196 10.5613 21.6196 7.38714C21.6196 4.21285 22.1525 1.63931 22.8097 1.63931Z"
        fill="black"
      />
    </svg>
  );
};

export default Medium;
