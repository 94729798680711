import { useState, useEffect, useRef } from "react";
import { Chevron } from "icons";
import { useMobile } from "util/device";
import {
  DropdownWrapper,
  DropdownContent,
  Option,
  GreenLight,
  Button,
} from "./styled";

interface DropdownButtonProps {
  buttonTitle: string;
  options: any[];
}

const Dropdown = ({ buttonTitle, options }: DropdownButtonProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const isMobile = useMobile();
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };
  return (
    <DropdownWrapper>
      <Button ref={dropdownRef} onClick={() => toggleDropdown()}>
        <GreenLight />
        <p>{buttonTitle}</p>
        <Chevron stroke={isMobile ? "black" : "white"} />
      </Button>
      {dropdownOpen && (
        <DropdownContent>
          {options.map((option, index) => (
            <Option key={index}>{option}</Option>
          ))}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
