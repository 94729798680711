const Juno = (props: any) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9999 1.2889C6.98073 1.2889 1.2889 6.97831 1.2889 13.9999C1.2889 21.0216 6.98073 26.7109 13.9999 26.7109C21.0191 26.7109 26.7109 21.0216 26.7109 13.9999C26.7109 6.98083 21.0191 1.2889 13.9999 1.2889ZM0 13.9999C0 6.26634 6.26907 0 13.9999 0C21.7309 0 28 6.26898 28 13.9999C28 21.7336 21.7308 28 13.9999 28C6.26907 28 0 21.7336 0 13.9999Z"
        fill="#FFEBD2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5267 10.6642L15.9951 10.5173L18.395 7.91892L17.4284 6.95481L14.8274 9.34701L14.6856 5.81543H13.322L13.175 9.34701L10.5766 6.94709L9.61253 7.91374L12.0073 10.5147L8.47314 10.6539V12.0202L12.0047 12.1671L9.60477 14.7655L10.5715 15.7296L13.1312 13.3735L13.2446 19.0962L11.2004 19.2457V20.5165L13.2756 20.6738L13.3142 22.6613H14.6779L14.7191 20.6764L16.8148 20.5165V19.2457L14.75 19.0962L14.866 13.3761L17.4232 15.7348L18.3873 14.7706L15.9951 12.1697L19.5267 12.0279V10.6642Z"
        fill="#FFEBD2"
      />
    </svg>
  );
};

export default Juno;
