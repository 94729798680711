import React from "react";
import { Dropdown } from "components";
import { Juno, Near } from "icons";
import {
  Container,
  Image,
  MenuContainer,
  MenuItem,
  IconWrapper,
  StyledLink,
} from "./styled";

const Navbar: React.FC = () => {
  const handleClick = () => {
    const targetDiv = window.document.getElementById("showCase");
    targetDiv?.scrollIntoView({ behavior: "smooth" });
  };
  const handleCollectionClick = () => {
    const targetDiv = window.document.getElementById("featuredCollection");
    targetDiv?.scrollIntoView({ behavior: "smooth" });
  };
  const handleWhitelist = () => {
    const targetDiv = window.document.getElementById("gleamEntry");
    targetDiv?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container>
      <Image src="/images/logotext.svg" />
      <MenuContainer>
        <MenuItem onClick={handleWhitelist}>Whitelist</MenuItem>
        <MenuItem onClick={handleClick}>How it works</MenuItem>
        <MenuItem onClick={handleCollectionClick}>Collections</MenuItem>
      </MenuContainer>
      <Dropdown
        buttonTitle="Marketplace"
        options={[
          <StyledLink
          >
            <Juno />
            <p>JUNO</p>
            <IconWrapper>
            </IconWrapper>
          </StyledLink>,
          <StyledLink
          >
            <Near />
            <p>NEAR</p>
            <IconWrapper>
            </IconWrapper>
          </StyledLink>,
        ]}
      />
    </Container>
  );
};

export default Navbar;
