const Email = (props: any) => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.3334 2.94922V11.2826C18.3334 11.8351 18.1139 12.365 17.7232 12.7557C17.3325 13.1464 16.8026 13.3659 16.2501 13.3659H3.75008C3.19755 13.3659 2.66764 13.1464 2.27694 12.7557C1.88624 12.365 1.66675 11.8351 1.66675 11.2826V2.94922"
        stroke="black"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 2.94954C18.3334 2.39701 18.1139 1.86711 17.7232 1.47641C17.3325 1.0857 16.8026 0.866211 16.2501 0.866211H3.75008C3.19755 0.866211 2.66764 1.0857 2.27694 1.47641C1.88624 1.86711 1.66675 2.39701 1.66675 2.94954L8.89591 7.46343C9.22702 7.67038 9.60962 7.7801 10.0001 7.7801C10.3905 7.7801 10.7731 7.67038 11.1042 7.46343L18.3334 2.94954Z"
        stroke="black"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Email;
