import styled from "styled-components";

interface ButtonProps {
  variant?: string;
  children: React.ReactNode;
  onClick?: any;
}

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  children,
  onClick,
}) => {
  return (
    <ButtonWrapper variant={variant} onClick={onClick}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;

const ButtonWrapper = styled.button<{ variant: string }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-radius: 9999px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  height: 48px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 13px 32px;
  position: relative;
  will-change: transform;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid white;
  cursor: pointer;
  width: fit-content;
  font-size: 15px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 500;
`;
