import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Footer } from "containers";
import { Home } from "pages";
import GlobalStyles from "style/global";
import { Analytics } from "@vercel/analytics/react";

function App(): JSX.Element {
  return (
    <>
      <Analytics />
      <Router>
        <GlobalStyles />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
