const Telegram = (props: any) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0001 0.386719C5.37103 0.386719 6.10352e-05 5.75764 6.10352e-05 12.3866C6.10352e-05 19.0156 5.37103 24.3865 12.0001 24.3865C18.6291 24.3865 24.0001 19.0156 24.0001 12.3866C24.0001 5.75764 18.6291 0.386719 12.0001 0.386719ZM17.8936 8.60762L15.9243 17.8882C15.7791 18.5463 15.3872 18.7059 14.8404 18.3963L11.8404 16.185L10.3936 17.5785C10.2339 17.7382 10.0984 17.8737 9.78877 17.8737L10.0017 14.8205L15.5614 9.79793C15.8033 9.58503 15.5081 9.46407 15.1888 9.67697L8.3178 14.0027L5.35651 13.0786C4.71296 12.8753 4.69845 12.435 5.492 12.1253L17.0614 7.66408C17.5984 7.47053 18.0678 7.79472 17.8936 8.60762Z"
        fill="black"
      />
    </svg>
  );
};

export default Telegram;
