import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 60px 24px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    padding-inline: 20px;
  }
`;

export const Image = styled.img`
  height: 40px;
`;

export const MenuContainer = styled.div`
  display: flex;
  column-gap: 48px;
  @media (max-width: 1024px) {
    padding-inline: 20px;
  }
  @media (max-width: 860px) {
    display: none;
  }
`;

export const MenuItem = styled.div`
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  cursor: pointer;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const IconWrapper = styled.div`
  transform: rotate(270deg);
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
