const Instagram = (props: any) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.849609 9.39453C0.849609 5.79939 0.849609 4.00182 1.7063 2.70708C2.08886 2.12892 2.584 1.63378 3.16216 1.25122C4.4569 0.394531 6.25447 0.394531 9.84961 0.394531C13.4447 0.394531 15.2423 0.394531 16.5371 1.25122C17.1152 1.63378 17.6104 2.12892 17.9929 2.70708C18.8496 4.00182 18.8496 5.79939 18.8496 9.39453C18.8496 12.9897 18.8496 14.7872 17.9929 16.082C17.6104 16.6601 17.1152 17.1553 16.5371 17.5378C15.2423 18.3945 13.4447 18.3945 9.84961 18.3945C6.25447 18.3945 4.4569 18.3945 3.16216 17.5378C2.584 17.1553 2.08886 16.6601 1.7063 16.082C0.849609 14.7872 0.849609 12.9897 0.849609 9.39453ZM14.5089 9.39476C14.5089 11.9681 12.4228 14.0542 9.84945 14.0542C7.27613 14.0542 5.19004 11.9681 5.19004 9.39476C5.19004 6.82144 7.27613 4.73535 9.84945 4.73535C12.4228 4.73535 14.5089 6.82144 14.5089 9.39476ZM9.84945 12.4778C11.5521 12.4778 12.9325 11.0975 12.9325 9.39476C12.9325 7.69206 11.5521 6.31176 9.84945 6.31176C8.14675 6.31176 6.76645 7.69206 6.76645 9.39476C6.76645 11.0975 8.14675 12.4778 9.84945 12.4778ZM14.6929 5.59608C15.2975 5.59608 15.7877 5.10591 15.7877 4.50126C15.7877 3.8966 15.2975 3.40643 14.6929 3.40643C14.0882 3.40643 13.5981 3.8966 13.5981 4.50126C13.5981 5.10591 14.0882 5.59608 14.6929 5.59608Z"
        fill="black"
      />
    </svg>
  );
};

export default Instagram;
