import {
  IntroArea,
  FeaturedCollection,
  PowerfulFeatures,
  GleamEntry,
  OriginalWork,
  Partners,
  Showcase,
  Vision,
} from "containers";

const Home: React.FC = () => {
  return (
    <>
      <IntroArea />
      <div id="featuredCollection">
        <FeaturedCollection />
      </div>
      <div id="gleamEntry">
      <GleamEntry />
      </div>
      <PowerfulFeatures />
      <OriginalWork />
      <div id="showCase">
        <Showcase />
      </div>
      <Vision />
      <Partners />
    </>
  );
};

export default Home;
