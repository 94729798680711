const Location = (props: any) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8609 8.65577C14.8609 14.0346 9.35174 17.3484 8.20685 17.9795C8.14342 18.0145 8.07217 18.0329 7.99974 18.0329C7.9273 18.0329 7.85605 18.0145 7.79263 17.9795C6.64688 17.3484 1.1394 14.0346 1.1394 8.65577C1.1394 4.36779 3.71219 1.36621 8.00016 1.36621C12.2881 1.36621 14.8609 4.36779 14.8609 8.65577Z"
        stroke="black"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.56982 8.22726C4.56982 9.13705 4.93124 10.0096 5.57456 10.6529C6.21788 11.2962 7.09041 11.6576 8.0002 11.6576C8.91 11.6576 9.78253 11.2962 10.4258 10.6529C11.0692 10.0096 11.4306 9.13705 11.4306 8.22726C11.4306 7.31746 11.0692 6.44493 10.4258 5.80161C9.78253 5.15829 8.91 4.79688 8.0002 4.79688C7.09041 4.79688 6.21788 5.15829 5.57456 5.80161C4.93124 6.44493 4.56982 7.31746 4.56982 8.22726V8.22726Z"
        stroke="black"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Location;
