const Discord = (props: any) => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.967 2.09956C22.5659 5.92075 23.8494 10.231 23.3696 15.193C23.3676 15.214 23.3567 15.2333 23.3394 15.2459C21.3713 16.6911 19.4645 17.5681 17.5844 18.1498C17.5697 18.1542 17.5541 18.154 17.5396 18.1491C17.5251 18.1442 17.5125 18.1349 17.5035 18.1225C17.0692 17.5181 16.6745 16.8809 16.3288 16.2119C16.3089 16.1724 16.327 16.125 16.3679 16.1095C16.9947 15.8733 17.5907 15.5902 18.164 15.2551C18.2092 15.2287 18.212 15.1639 18.1703 15.1329C18.0486 15.0425 17.9281 14.9476 17.8128 14.8527C17.7912 14.8351 17.7621 14.8317 17.7377 14.8435C14.0158 16.5622 9.93857 16.5622 6.17263 14.8435C6.14818 14.8326 6.11912 14.8363 6.09812 14.8536C5.98306 14.9485 5.86225 15.0425 5.74172 15.1329C5.70001 15.1639 5.70346 15.2287 5.74891 15.2551C6.32221 15.5839 6.91822 15.8733 7.54415 16.1106C7.58471 16.1261 7.60398 16.1724 7.58385 16.2119C7.24557 16.8818 6.85091 17.519 6.4085 18.1233C6.38923 18.1478 6.35759 18.159 6.32767 18.1498C4.45649 17.5681 2.54964 16.6911 0.581525 15.2459C0.565129 15.2333 0.553335 15.2131 0.551609 15.1921C0.150622 10.9 0.967842 6.55416 3.95109 2.09869C3.95828 2.0869 3.96921 2.07769 3.98186 2.07223C5.44976 1.39854 7.02235 0.902916 8.666 0.619865C8.69591 0.615262 8.72583 0.62907 8.74136 0.655534C8.94445 1.0151 9.17658 1.47621 9.33364 1.85304C11.0662 1.5884 12.8257 1.5884 14.5945 1.85304C14.7516 1.48426 14.9757 1.0151 15.1779 0.655534C15.1851 0.642405 15.1962 0.631888 15.2098 0.625482C15.2233 0.619076 15.2385 0.61711 15.2532 0.619865C16.8977 0.903779 18.4703 1.39941 19.9371 2.07223C19.95 2.07769 19.9607 2.0869 19.967 2.09956ZM10.2144 10.2603C10.2326 8.99146 9.30746 7.94152 8.14621 7.94152C6.99445 7.94152 6.07828 8.98225 6.07828 10.2603C6.07828 11.5381 7.01257 12.5788 8.14621 12.5788C9.29826 12.5788 10.2144 11.5381 10.2144 10.2603ZM17.8608 10.2603C17.8789 8.99146 16.9538 7.94152 15.7929 7.94152C14.6408 7.94152 13.7247 8.98225 13.7247 10.2603C13.7247 11.5381 14.6589 12.5788 15.7929 12.5788C16.9538 12.5788 17.8608 11.5381 17.8608 10.2603Z"
        fill="black"
      />
    </svg>
  );
};

export default Discord;
