import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 320px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  overflow: hidden;
  * {
    font-family: "EditorNote";
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 240px;
  position: relative;
  background: url(/images/frame-background.png);
  img {
    width: 100%;
    height: 100%;
  }
`;

export const OffsetButtonWrapper = styled.button`
  width: 115px;
  height: 32px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  border-radius: 999px;
  padding: 6px 8px;
  position: absolute;
  top: 8px;
  left: 197px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 0px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 300;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`;

export const CardContentWrapper = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  h1 {
    font-size: 18px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`;

export const Description = styled.div`
  font-size: 13px;
  color: #737373;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;
