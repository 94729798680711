import { DefaultTheme } from "styled-components";
import { Colors, Fonts } from "./types";

const lightColors: Colors = {
  primary: "#FFFFFF",
  secondary: "#000000",
  primaryBackground: "#000000",
  secondaryBackground: "#FFFFFF",
};
const fonts: Fonts = {
  primary: "EditorNote",
  secondary: "Eina03",
};
const lightTheme: DefaultTheme = {
  isDark: false,
  colors: lightColors,
  fonts: fonts,
};

export default lightTheme;
