const Github = (props: any) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.966 0.463867C5.51481 0.463867 0.286011 5.92034 0.286011 12.6524C0.286011 18.0355 3.63321 22.6037 8.27321 24.2199C8.85561 24.3334 9.07321 23.9528 9.07321 23.6322C9.07321 23.3383 9.06041 22.3833 9.06041 21.3614C5.80921 22.0961 5.12441 19.9255 5.12441 19.9255C4.59321 18.5163 3.82521 18.1423 3.82521 18.1423C2.76281 17.3876 3.90201 17.401 3.90201 17.401C5.07321 17.4878 5.69401 18.6566 5.69401 18.6566C6.73721 20.5199 8.42681 19.9789 9.09241 19.6717C9.19481 18.8836 9.50201 18.3494 9.83481 18.0421C7.24281 17.7349 4.51001 16.6864 4.51001 12.018C4.51001 10.6889 4.96441 9.60029 5.71321 8.74542C5.59161 8.4382 5.19481 7.19597 5.82841 5.51962C5.82841 5.51962 6.80761 5.19237 9.04121 6.76853C9.97561 6.50139 10.974 6.36114 11.966 6.36114C12.958 6.36781 13.9564 6.50139 14.8908 6.76853C17.118 5.19237 18.0972 5.51962 18.0972 5.51962C18.7308 7.19597 18.334 8.4382 18.2124 8.74542C18.9612 9.60029 19.4156 10.6889 19.4156 12.018C19.4156 16.6997 16.6828 17.7282 14.0844 18.0288C14.5004 18.4095 14.878 19.1508 14.878 20.2862C14.878 21.9158 14.8652 23.2315 14.8652 23.6322C14.8652 23.9594 15.0764 24.3334 15.6652 24.2199C20.3052 22.6037 23.646 18.0421 23.646 12.6524C23.646 5.92034 18.4172 0.463867 11.966 0.463867Z"
        fill="#181616"
      />
      <path
        d="M4.70841 17.9619C4.68281 18.022 4.59321 18.0421 4.51001 18.002C4.42681 17.9619 4.37561 17.8818 4.40761 17.8217C4.43321 17.7616 4.52281 17.7415 4.60601 17.7816C4.68921 17.8217 4.74041 17.9018 4.70841 17.9619Z"
        fill="#181616"
      />
      <path
        d="M5.18201 18.5162C5.12441 18.5696 5.01561 18.5429 4.94521 18.4627C4.86841 18.3759 4.85561 18.2624 4.91321 18.2089C4.97081 18.1555 5.07321 18.1822 5.15001 18.2624C5.22681 18.3492 5.23961 18.4627 5.18201 18.5162Z"
        fill="#181616"
      />
      <path
        d="M5.64281 19.2174C5.57241 19.2708 5.45721 19.224 5.38041 19.1105C5.31001 19.0036 5.31001 18.8701 5.38041 18.8166C5.45081 18.7632 5.56601 18.81 5.64281 18.9168C5.71961 19.0304 5.71961 19.1639 5.64281 19.2174Z"
        fill="#181616"
      />
      <path
        d="M6.2764 19.8921C6.2124 19.9656 6.078 19.9455 5.9756 19.8453C5.8732 19.7452 5.8476 19.6049 5.9116 19.5314C5.9756 19.458 6.1164 19.478 6.2124 19.5782C6.3148 19.6851 6.3404 19.8253 6.2764 19.8921Z"
        fill="#181616"
      />
      <path
        d="M7.14681 20.2859C7.12121 20.3794 6.98681 20.4262 6.85241 20.3861C6.71801 20.346 6.63481 20.2325 6.66041 20.139C6.68601 20.0455 6.82041 19.9987 6.95481 20.0388C7.08281 20.0789 7.17241 20.1924 7.14681 20.2859Z"
        fill="#181616"
      />
      <path
        d="M8.10042 20.3595C8.10682 20.4597 7.99162 20.5465 7.85082 20.5465C7.71002 20.5532 7.60122 20.4664 7.59482 20.3662C7.59482 20.266 7.70362 20.1792 7.84442 20.1792C7.98522 20.1792 8.10042 20.2593 8.10042 20.3595Z"
        fill="#181616"
      />
      <path
        d="M8.99002 20.2058C9.00922 20.306 8.91322 20.4062 8.77242 20.4329C8.63802 20.4596 8.51002 20.3995 8.49722 20.2993C8.47802 20.1992 8.58042 20.099 8.71482 20.0723C8.84922 20.0455 8.97082 20.1057 8.99002 20.2058Z"
        fill="#181616"
      />
    </svg>
  );
};

export default Github;
